<template>
    <div class='instructions'>
        <!-- 正面 -->
        <div class="content">
            <div class="contItem user1">
                <template v-if="dataInfo.CompanyName == '大聚雅'">
                    <div class="dajuya-img">
                        <img class="img" src="../../assets/dajuya.png" alt="">
                    </div>
                </template>
                <template v-else>
                    <div style="font-size: 60px;font-weight: bold;padding-top: 20px;">Hi</div>
                    <div style="font-size: 54px">{{ dataInfo.UserName }}</div>
                </template>
            </div>
            <div class="contItem details2">
                <div class="logo">
                    <img class="img" :src="dataInfo.CompanyTemp?.CompanyLogoImage" alt="">
                </div>
                <div class="decoding">{{ dataInfo.CompanyTemp?.ContentTitle }}</div>
                <div class="text">
                    {{ dataInfo.CompanyTemp?.Contents }}
                </div>
            </div>
            <div class="contItem take3">
                <div class="usaHead"><span style="font-weight: bold;">For</span> {{ dataInfo.UserName }}</div>
                <div style="line-height: 50px;">请根据盒子内部产品标号依次服用</div> 
                <div class="take3-content" v-if="dataInfo.MethodColorList">
                    <template v-if="dataInfo.MethodColorList.length == 4">
                        <div class="methods">
                            <template v-for="(item, i) in methodsList4">
                                <div class="methods-item">
                                    <div class="head" :style="{ border: `1px solid ${item?.ColorCode}` }">
                                        <div class="title">
                                            <template v-if="item?.ColorName == '瘦子菌(蓝)色'">
                                                蓝色
                                            </template>
                                            <template v-else-if="item?.ColorName == '瘦子菌(粉)色'">
                                                粉色
                                            </template>
                                            <template v-else>
                                                {{ item.ColorName }}
                                            </template>
                                        </div>
                                        <div class="imgags" :style="{ backgroundColor: item?.ColorCode }">
                                            <template v-if="item.Remark == '早'">
                                                <img :src="item.ColorImage">
                                            </template>
                                            <template v-else>
                                                <img :src="item.ColorImage">
                                            </template>
                                        </div>
                                    </div>
                                    <template v-if="i == 0">
                                        <div class="methods-text">
                                            {{ dataInfo.MethLists[0].MethName }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="methods-text">
                                            {{ dataInfo.MethLists[1].MethName }}
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="take3-head" :style="{ border: `1px solid ${dataInfo.MethodColorList[0]?.ColorCode}` }">
                            <div class="title">
                                <template v-if="dataInfo.MethodColorList[0].ColorName == '瘦子菌(蓝)色'">
                                    蓝色
                                </template>
                                <template v-else-if="dataInfo.MethodColorList[0].ColorName == '瘦子菌(粉)色'">
                                    粉色
                                </template>
                                <template v-else>
                                    {{ dataInfo.MethodColorList[0].ColorName }}
                                </template>
                            </div>
                            <div class="imgags" :style="{ backgroundColor: dataInfo.MethodColorList[0]?.ColorCode }">
                                <template v-if="dataInfo.MethodColorList[0].Remark == '早'">
                                    <img :src="dataInfo.MethodColorList[0]?.ColorImage">
                                </template>
                                <template v-else>
                                    <img :src="dataInfo.MethodColorList[0]?.ColorImage"
                                        :style="{ backgroundColor: dataInfo.MethodColorList[0]?.ColorCode }">
                                </template>
                            </div>
                        </div>
                        <div class="take3-methods">
                            {{ dataInfo.MethLists[0].MethName }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="contItem take4">
                <div class="methods">
                    <template v-for="(item, i) in methodsList">
                        <div class="methods-item">
                            <div class="head" :style="{ border: `1px solid ${item?.ColorCode}` }">
                                <div class="title">
                                    <template v-if="item?.ColorName == '瘦子菌(蓝)色'">
                                        蓝色
                                    </template>
                                    <template v-else-if="item?.ColorName == '瘦子菌(粉)色'">
                                        粉色
                                    </template>
                                    <template v-else>
                                        {{ item.ColorName }}
                                    </template>
                                </div>
                                <div class="imgags" :style="{ backgroundColor: item?.ColorCode }">
                                    <template v-if="item.Remark == '早'">
                                        <img :src="item.ColorImage">
                                    </template>
                                    <template v-else>
                                        <img :src="item.ColorImage">
                                    </template>
                                </div>
                            </div>
                            <template v-if="dataInfo.MethodColorList.length == 4">
                                <template v-if="i == 0">
                                    <div class="methods-text">
                                        {{ dataInfo.MethLists[2].MethName }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="methods-text">
                                        {{ dataInfo.MethLists[3].MethName }}
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="i == 0">
                                    <div class="methods-text">
                                        {{ dataInfo.MethLists[1].MethName }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="methods-text">
                                        {{ dataInfo.MethLists[2].MethName }}
                                    </div>
                                </template>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="footer">
                    <p> 250--300毫升温水冲服，可根据自己口味增加新鲜柠檬，薄荷;</p>
                    <p> 如需在饭前服用的保持空腹时间越长，吸收效果越好;</p>
                </div>
            </div>
        </div>
        <!-- 背面 -->
        <div class="content" v-if="dataInfo.CompanyName == '生命之鼎'">
            <div class="contItem head5">
                <div class="headTitle" style="padding-top: 30px;">
                    <div class="title">{{ title[0] }}</div>
                    <div class="subtitle">{{ title[1] }}</div>
                </div>
                <div class="statement">
                    <div>
                        <li style="line-height: 30px;">本产品为{{ dataInfo.CompositionFormula?.ProjectName
                        }}<template v-if="dataInfo.CompanyName == '营养解码'">管理营养定制
                            </template><template v-else>管理营养膳食</template>
                            ，不用于治疗、诊断、预防或治愈疾病。</li>
                        <li style="line-height: 30px;">本产品采用密封包装，收到请尽快服用 </li>
                    </div>
                </div>
            </div>
            <div class="contItem food6">
                <img class="img" :src="dataInfo.CompositionFormula?.ProjectCompositionFormulaImage" alt="">
            </div>
            <div class="contItem certificate7-1">
                <img style="width: 440px; height: 420px;" class="img" :src="dataInfo.CompanyTemp?.FormulaCert">
            </div>
            <div class="contItem comtentlife8" style="padding-top: 80px;">
                <div class="text-content">
                    <div class="text-list">
                        <template v-if="dataInfo.CompanyName == '薇凯'">
                            <div class="text-item">【产品名称】 海博蒂HAIBODI植萃新安宁</div>
                        </template>
                        <template v-else>
                            <div class="text-item">【产品名称】 个性化{{ dataInfo.CompositionFormula?.ProjectName }}管理营养定制</div>
                        </template>
                        <div>【贮存条件】 请置于阴凉干燥处保存</div>
                    </div>
                    <div class="text-list">
                        <template v-if="dataInfo.CompanyName == '薇凯'">
                            <div class="text-item">【生产日期】 {{ dates }}</div>
                        </template>
                        <template v-else>
                            <div class="text-item">【生产日期】 见外包装盒底部</div>
                        </template>
                        <div class="text-item" style="padding-right: 95px;">【保质期】 {{ dataInfo.CompanyTemp?.QualityGuarantee
                        }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content" v-else>
            <div class="contItem head5">
                <div class="headTitle" style="padding-top: 30px;">
                    <div class="title">{{ title[0] }}</div>
                    <div class="subtitle">{{ title[1] }}</div>
                </div>
                <div class="statement">
                    <div>
                        <li style="line-height: 30px;">本产品为{{ dataInfo.CompositionFormula?.ProjectName
                        }}<template v-if="dataInfo.CompanyName == '营养解码'">管理营养定制
                            </template><template v-else>管理营养膳食</template>
                            ，不用于治疗、诊断、预防或治愈疾病。</li>
                        <li style="line-height: 30px;">本产品采用密封包装，收到请尽快服用 </li>
                    </div>
                </div>
            </div>
            <div class="contItem food6">
                <img class="img" :src="dataInfo.CompositionFormula?.ProjectCompositionFormulaImage" alt="">
            </div>
            <div class="contItem certificate7">
                <img style="width: 440px; height: 420px;" class="img" :src="dataInfo.CompanyTemp?.FormulaCert">
            </div>
            <div class="contItem comtentlife8">
                <div class="text-content">
                    <div class="text-list">
                        <template v-if="dataInfo.CompanyName == '薇凯'">
                            <div class="text-item">【产品名称】 海博蒂HAIBODI植萃新安宁</div>
                        </template>
                        <template v-else>
                            <div class="text-item">【产品名称】 个性化{{ dataInfo.CompositionFormula?.ProjectName }}管理营养定制</div>
                        </template>
                        <div>【贮存条件】 请置于阴凉干燥处保存</div>
                    </div>
                    <div class="text-list">
                        <template v-if="dataInfo.CompanyName == '薇凯'">
                            <div class="text-item">【生产日期】 {{ dates }}</div>
                        </template>
                        <template v-else>
                            <div class="text-item">【生产日期】 见外包装盒底部</div>
                        </template>
                        <div class="text-item" style="padding-right: 95px;">【保质期】 {{ dataInfo.CompanyTemp?.QualityGuarantee
                        }}</div>
                    </div>
                </div>
                <div class="imgages">
                    <img class="img" :src="dataInfo.CompanyTemp?.FootImage" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { instructionsPdfOffline } from '@/api/index'

export default {
    name: 'instructionsPdfOffline',
    components: {

    },
    data() {
        return {
            dataInfo: {},
            takeList: [],
            title: [],
            dates: '',
            methodsList4: [],
            methodsList: [],
            methods3: '',
            takeMethods41: '55',
            takeMethods42: '66',
        }
    },
    props: {

    },
    created() {
        this.getinstructionsData()
    },
    methods: {
        async getinstructionsData() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            this.dates = `${year}年${month}月${day}日`
            const id = this.$query('id')
            const parmas = {
                id
            }
            const { data } = await instructionsPdfOffline(parmas)
            this.dataInfo = data
            this.title = data.CompanyTemp.CompanyTitle.split(',')
            if (data.MethodColorList.length == 4) {
                this.methodsList4.push(this.dataInfo.MethodColorList[0])
                this.methodsList4.push(this.dataInfo.MethodColorList[1])
                this.methodsList.push(this.dataInfo.MethodColorList[2])
                this.methodsList.push(this.dataInfo.MethodColorList[3])
                // this.methodsList = this.dataInfo.MethodColorList.filter((v, i) => i != 0 || i != 1)
            } else {
                this.methodsList = this.dataInfo.MethodColorList.filter((v, i) => i != 0)
            }

        },
        //   处理服用方法
        handletakeList(data) {
            data.MethodColorList.map((v, i) => {
                switch (i) {
                    case 0:
                        this.takeList.push({
                            takeMethod: data.Meth1,
                            imageUrl: v.ColorImage
                        })
                        break
                    case 1:
                        this.takeList.push({
                            takeMethod: data.Meth2,
                            imageUrl: v.ColorImage
                        })
                        break
                    case 2:
                        this.takeList.push({
                            takeMethod: data.Meth3,
                            imageUrl: v.ColorImage
                        })
                        break
                    case 3:
                        this.takeList.push({
                            takeMethod: data.Meth4,
                            imageUrl: v.ColorImage
                        })
                        break
                    default:
                        this.takeList = []
                }
            })
        }
    },
}
</script>
<style lang='scss' scoped>
.instructions {
    width: 100%;
    height: 100%;

    .content {
        width: 550px;
        height: 1694px;
        padding: 0 40px;

        .contItem {
            width: 100%;
            height: 420px;
            padding: 30px 0 15px 0;
        }

        .user1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // padding-bottom: 120px;
            font-size: 40px;

            .dajuya-img {
                // width: 459px;
                // height: 447px;
                padding-top: 140px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .details2 {
            .logo {
                width: 100px;
                height: 40px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }

            .delHead {
                font-size: 28px;
            }

            .decoding {
                padding-top: 40px;
                font-size: 18px;
            }

            .text {
                line-height: 30px;
                text-indent: 2em;
                padding: 25px 0;
                border-bottom: 1px solid #000;
            }
        }

        .take3 {
            border-bottom: 1px dashed #cacacb;

            .usaHead {
                font-size: 28px;
            }

            .take3-content {
                margin: 30px auto;
                width: 460px;
                height: 300px;

                .methods {
                    display: flex;
                    justify-content: space-around;

                    .head {
                        width: 160px;
                        height: 180px;
                        margin: 0 auto;
                        border-radius: 5px;

                        .title {
                            height: 40px;
                            text-align: center;
                            line-height: 40px;
                        }

                        .imgags {
                            width: 159px;
                            height: 139px;
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;

                            img {
                                margin: 0 auto;
                                width: 159px;
                                height: 139px;
                            }
                        }
                    }

                    .methods-text {
                        text-align: center;
                        line-height: 40px;
                        padding-top: 20px;
                    }
                }

                .take3-head {
                    width: 160px;
                    height: 180px;
                    margin: 0 auto;
                    border: 1px solid #000;
                    border-radius: 5px;

                    .title {
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                    }

                    .imgags {
                        width: 159px;
                        height: 139px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;

                        img {
                            margin: 0 auto;
                            width: 159px;
                            height: 139px;
                        }
                    }
                }

                .take3-methods {
                    padding-top: 30px;
                    text-align: center;
                }
            }
        }

        .take4 {
            .methods {
                display: flex;
                justify-content: space-around;

                .head {
                    width: 160px;
                    height: 180px;
                    margin: 0 auto;
                    border-radius: 5px;

                    .title {
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                    }

                    .imgags {
                        width: 159px;
                        height: 139px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;

                        img {
                            margin: 0 auto;
                            width: 159px;
                            height: 139px;
                        }
                    }
                }

                .methods-text {
                    text-align: center;
                    line-height: 40px;
                    padding-top: 20px;
                }
            }

            .footer {
                padding-top: 50px;

                p {
                    line-height: 30px;
                }
            }
        }

        .head5 {
            .logo {
                width: 100px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }

            .headTitle {
                font-size: 22px;
                margin-top: 35px;
                font-weight: bold;

                .subtitle {
                    font-size: 20px;
                    margin: 5px 0 10px 0;
                }
            }

            .statement {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 10px 0;
            }
        }

        .food6 {
            img {
                width: 480px;
                height: 380px;
            }
        }

        .certificate7-1{
            padding-bottom: 20px;
        }
        .certificate7 {
            padding: 60px 0;
        }

        .comtentlife8 {
            .text-content {
                .text-list {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;

                    .text-item {
                        line-height: 24px;
                    }
                }
            }

            .imgages {
                padding-top: 80px;
            }

        }
    }
}
</style>
